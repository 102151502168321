import { useEffect, useState, useRef } from "react"
import BancFileUploader from "./BancFileUploader"

interface Transaction {
	hash?: string,
	date: Date,
	order: number,
	ref: number,
	amount: number,
	balance: number,
	concept: string,
	description: string,
}

const Banca = () => {
	const [transactions, setTransactions] = useState<Transaction[]>([])
	const [nextListCursor, setNextListCursor] = useState<any | undefined>()
	const [listCursor, setListCursor] = useState<any | undefined>()
	const [isLoading, setIsLoading] = useState(false)

	const observerTarget = useRef<HTMLDivElement>(null)

	useEffect(() => {
		setIsLoading(true)
		const params = new URLSearchParams()
		if (listCursor) {
			params.append('date', listCursor.date)
			params.append('order', listCursor.order)
		}
		fetch(`http://localhost:8000/bank${listCursor ? `?${params.toString()}` : ''}`)
			.then(response => response.json())
			.then(list => {
				setTransactions(prev => {
					const additional = list.transactions.filter((transaction: Transaction) => !prev.find((prevTransaction: Transaction) => prevTransaction.hash === transaction.hash))
					return [...prev, ...additional].sort((a, b) => a.date - b.date)
				})
				setNextListCursor(list.nextCursor)
				setIsLoading(false)
			})
			.catch(error => {
				console.error("Error fetching transactions:", error)
				setIsLoading(false)
			})
	}, [listCursor])

	useEffect(() => {
		const observer = new IntersectionObserver(entries => {
			if (entries[0].isIntersecting && !isLoading && nextListCursor) {
				setListCursor(nextListCursor)
			}
		}, {
			threshold: 0,
			rootMargin: '0px 0px 100px 0px'
		})
		const currentTarget = observerTarget.current		
		if (currentTarget)  observer.observe(currentTarget)
		return () => {
			if (currentTarget) observer.unobserve(currentTarget)
		}
	}, [isLoading, nextListCursor])
	
	return (
		<div className="banca">
			<div className="header">
				<h2>💸 Family money</h2>
				<BancFileUploader />
			</div>
			{transactions.length > 0 && (
				<div className="transactions-grid">
					<div className="grid-row" key="header">
						<div className="grid-cell">Fecha</div>
						<div className="grid-cell">Concepto</div>
						<div className="grid-cell">Descripción</div>
						<div className="grid-cell">Importe</div>
						<div className="grid-cell">Saldo</div>
					</div>
					{transactions.map((transaction, index) => (
						<div
							key={index}
							className={`grid-row ${transaction.amount > 0 ? 'green' : (transaction.balance < 0 ? 'red' : '')}`}
						>
							<div className="grid-cell">
								{new Date(transaction.date).toLocaleDateString('es-ES', { dateStyle: 'medium' })}
							</div>
							<div className="grid-cell">
								{transaction.concept}
							</div>
							<div className="grid-cell">
								{transaction.description}
							</div>
							<div className="grid-cell money">
								{transaction.amount.toLocaleString('es-ES', { style: 'currency', currency: 'EUR', useGrouping: true })}
							</div>
							<div className="grid-cell money">
								{transaction.balance.toLocaleString('es-ES', { style: 'currency', currency: 'EUR', useGrouping: true })}
							</div>
						</div>
					))}
					{!isLoading &&
						<div ref={observerTarget}></div>
					}
					{isLoading && (
						<div className="grid-row loading">
							<div className="grid-cell loading-cell">
								Loading more transactions...
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	)
}

export default Banca
