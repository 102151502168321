import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Family from './Family'
import Join from './Join'
import Banca from './banca/Banca'

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/banca" element={<Banca />} />
				<Route path="/join/*" element={<Join />} />
				<Route path="*" element={<Family />} />
			</Routes>
		</Router>
	)
}

export default App
