import React, { useState, useCallback } from 'react'

const BancFileUploader = () => {
	const [inserted, setInserted] = useState<number | undefined>(undefined)
	const [isDragging, setIsDragging] = useState(false)
	const [isUploading, setIsUploading] = useState(false)

	interface DragEvent extends React.DragEvent<HTMLDivElement> {
		dataTransfer: DataTransfer
	}
	interface ChangeEvent extends React.ChangeEvent<HTMLInputElement> {
		target: HTMLInputElement & EventTarget
	}

	const onDrop = useCallback((event: DragEvent | ChangeEvent) => {
		event.preventDefault()
		setIsUploading(true)
		setInserted(undefined)
		setIsDragging(false)
		const files = 'dataTransfer' in event ? event.dataTransfer.files : event.target.files
		if (!files) return undefined
		const formData = new FormData()
		for (let i = 0; i < files.length; i++) {
			const file = files[i]
			if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type !== 'application/vnd.ms-excel') {
				console.error('Invalid file type. Please upload an Excel file.')
				return undefined
			} else {
				console.log('File type is valid', file.size)
			}
			formData.append('files', file)
		}
		fetch('http://localhost:8000/bank', {
				method: 'POST',
				body: formData,
			})
			.then(response => response.json())
			.then(data => {
				setIsUploading(false)
				setInserted(data.inserted)
			})
	}, [])

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault()
		setIsDragging(true)
	}

	const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault()
		setIsDragging(false)
	}

	return (
		<div
			onDrop={onDrop}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			className={`file-uploader${isDragging ? ' dragging' : ''}`}
		>
			<input type="file" multiple onChange={onDrop} style={{ display: 'none' }} id="fileInput" />
			<label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
				{!inserted && !isDragging && !isUploading &&
					<span>Echar aquí el Excel del banco, o tocar aquí para seleccionarlo</span>
				}
				{inserted && !isDragging && !isUploading &&
					<span><strong>{inserted}</strong> registros insertados!!!</span>
				}
				{isDragging && !isUploading &&
					<span>Suelta el archivo aquí</span>
				}
				{isUploading &&
					<div className="uploading-animation">
						<div className="spinner"></div>
					</div>
				}
			</label>
		</div>
	)
}

export default BancFileUploader
